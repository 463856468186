import React from 'react';
import { 
  createBrowserRouter,
  RouterProvider,
  Outlet
} from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import IconPreloader from './components/IconPreloader';

// Import pages
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import EventsPage from './pages/EventsPage';
import LocalChapters from './pages/LocalChapters';

/**
 * Main App component that sets up routing for the application
 * @returns {JSX.Element} The App component
 */
const App = () => {
  // Create router with future flags
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <HomePage />
        },
        {
          path: "about",
          element: <AboutPage />
        },
        {
          path: "contact",
          element: <ContactPage />
        },
        {
          path: "events",
          element: <EventsPage />
        },
        {
          path: "chapters",
          element: <LocalChapters />
        }
      ]
    }
  ], {
    future: {
      v7_startTransition: true,
    }
  });

  return <RouterProvider router={router} />;
}

// Layout component with Navbar and Footer
function AppLayout() {
  return (
    <div className="App">
      <ScrollToTop />
      <IconPreloader />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App; 