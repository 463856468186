import React from 'react';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer-container" role="contentinfo">
      <div className="footer-bottom">
        <small className="website-rights">MCGI Australia © {currentYear} All Rights Reserved</small>
      </div>
    </footer>
  );
}

export default Footer; 