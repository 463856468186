import React from 'react';
import './ContactPage.css';

function ContactPage() {
  return (
    <div className="contact-container">
      <div className="contact-hero">
        <div className="contact-hero-content">
          <h1>Get In Touch</h1>
          <p>We'd love to hear from you. Reach out with any questions about our congregation.</p>
        </div>
      </div>
      
      <div className="contact-section">
        <div className="contact-cards">
          <div className="contact-card">
            <div className="card-icon">
              <i className="fas fa-envelope"></i>
            </div>
            <h3>Email Us</h3>
            <p>Send us an email anytime</p>
            <a href="mailto:info@mcgi.org.au" className="contact-link">info@mcgi.org.au</a>
          </div>
          
          <div className="contact-card">
            <div className="card-icon">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h3>Call Us</h3>
            <p>Mon-Fri from 9am to 5pm</p>
            <a href="tel:+61450780530" className="contact-link">+61 450 780 530</a>
          </div>
          
          <div className="contact-card">
            <div className="card-icon">
              <i className="fas fa-clock"></i>
            </div>
            <h3>Service Hours</h3>
            <p>Join our services</p>
            <ul className="service-list">
              <li>Wednesday: 7:00 PM - 9:00 PM</li>
              <li>Saturday: 2:00 PM - 12:00 MN</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage; 