import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css';

function HeroSection() {
  const verses = useMemo(() => [
    {
      text: "Stand at the crossroads and look; ask for the ancient paths, ask where the good way is, and walk in it, and you will find rest for your souls.",
      reference: "Jeremiah 6:16",
      icon: "fas fa-road"
    },
    {
      text: "For we are God's handiwork, created in Christ Jesus to do good works, which God prepared in advance for us to do.",
      reference: "Ephesians 2:10",
      icon: "fas fa-hands-helping"
    },
    {
      text: "And now these three remain: faith, hope and love. But the greatest of these is love.",
      reference: "1 Corinthians 13:13",
      icon: "fas fa-heart"
    }
  ], []);

  const [currentVerseIndex, setCurrentVerseIndex] = useState(0);
  const [animationState, setAnimationState] = useState('fade-in');
  const [imageLoaded, setImageLoaded] = useState(false);
  
  // Use the public directory image path
  const heroBackgroundImage = `${process.env.PUBLIC_URL}/images/about-welcome.jpg`;

  useEffect(() => {
    // Preload the hero background image
    const img = new Image();
    img.src = heroBackgroundImage;
    img.onload = () => setImageLoaded(true);
    
    const interval = setInterval(() => {
      // Start fade out animation
      setAnimationState('fade-out');
      
      // After animation completes, change verse and fade in
      setTimeout(() => {
        setCurrentVerseIndex((prevIndex) => (prevIndex + 1) % verses.length);
        setAnimationState('fade-in');
      }, 1000); // Match with CSS transition time
    }, 8000); // Change verse every 8 seconds

    return () => clearInterval(interval);
  }, [verses.length, heroBackgroundImage]);

  const handleDotClick = useCallback((index) => {
    if (index !== currentVerseIndex) {
      setAnimationState('fade-out');
      setTimeout(() => {
        setCurrentVerseIndex(index);
        setAnimationState('fade-in');
      }, 1000);
    }
  }, [currentVerseIndex]);

  const currentVerse = verses[currentVerseIndex];

  return (
    <div 
      className={`hero-container ${imageLoaded ? 'image-loaded' : ''}`} 
      style={{
        background: imageLoaded 
          ? `linear-gradient(rgba(49, 130, 206, 0.7), rgba(44, 82, 130, 0.7)), url(${heroBackgroundImage}) center center/cover no-repeat` 
          : 'linear-gradient(rgba(49, 130, 206, 0.9), rgba(44, 82, 130, 0.9))'
      }}
      role="banner"
    >
      <div className="hero-two-columns">
        <div className="hero-left-column">
          <div className="hero-content">
            <h1>Welcome, MCGI Australia</h1>
            <p>Sharing peace, hope, and love through God's words</p>
            <div className="hero-btns">
              <Link to="/about" className="btn-primary">
                LEARN MORE
              </Link>
            </div>
          </div>
        </div>
        
        <div className="hero-right-column">
          <div className={`verse-card ${animationState}`} aria-live="polite">
            <div className="verse-content">
              <span className={`verse-icon ${currentVerse.icon}`} aria-hidden="true"></span>
              <p className="verse-text">{currentVerse.text}</p>
              <p className="verse-reference">{currentVerse.reference}</p>
            </div>
          </div>
          
          <div className="verse-dots" role="tablist" aria-label="Select verse">
            {verses.map((_, index) => (
              <button
                key={index}
                className={`verse-dot ${index === currentVerseIndex ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
                aria-selected={index === currentVerseIndex}
                role="tab"
                aria-label={`Verse ${index + 1}: ${verses[index].reference}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection; 