import React, { useEffect } from 'react';

// This component ensures that Font Awesome icons are properly loaded
// before they're rendered in the UI
function IconPreloader() {
  useEffect(() => {
    // Function to force icon refresh
    const refreshIcons = () => {
      // Find all Font Awesome icons on the page
      const icons = document.querySelectorAll('.fa, .fab, .fas, .far, .fal');
      
      if (icons.length > 0) {
        // Force a repaint on each icon
        icons.forEach(icon => {
          const display = window.getComputedStyle(icon).display;
          icon.style.display = 'none';
          // Trigger a reflow
          void icon.offsetHeight;
          icon.style.display = display;
        });
        
        console.log('Icons refreshed:', icons.length);
      } else {
        // If icons aren't found yet, try again in a moment
        setTimeout(refreshIcons, 100);
      }
    };

    // Initial delay to ensure components have mounted
    const timer = setTimeout(() => {
      refreshIcons();
      
      // Set up a mutation observer to catch dynamically added icons
      const observer = new MutationObserver((mutations) => {
        let shouldRefresh = false;
        
        mutations.forEach(mutation => {
          if (mutation.addedNodes.length) {
            mutation.addedNodes.forEach(node => {
              if (node.nodeType === 1 && (
                node.classList.contains('fa') ||
                node.classList.contains('fab') ||
                node.classList.contains('fas') ||
                node.classList.contains('far') ||
                node.classList.contains('fal') ||
                node.querySelector('.fa, .fab, .fas, .far, .fal')
              )) {
                shouldRefresh = true;
              }
            });
          }
        });
        
        if (shouldRefresh) {
          refreshIcons();
        }
      });
      
      // Start observing the document for added nodes
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
      
      // Cleanup function
      return () => {
        observer.disconnect();
      };
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  // This component doesn't render anything
  return null;
}

export default IconPreloader; 