import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './LocalChapters.css';

// Move chapters data outside component to prevent recreation on each render
const chapters = [
  {
    id: 1,
    name: 'MCGI Melbourne',
    region: 'Victoria',
    address: 'Unit 5 / 230 Blackshaws Rd, Altona North VIC 3025',
    phone: '+61 450 522 851',
    email: 'mcgimelbourne@mcgi.org.au',
    services: ['Sunday 10:00 AM', 'Wednesday 7:00 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'John Smith',
    memberCount: 120
  },
  {
    id: 2,
    name: 'MCGI Sydney',
    region: 'New South Wales',
    address: 'Unit 11 A, 516-524 Great Western Highway, St. Marys NSW',
    phone: '+61 436 309 638',
    email: 'mcgisydney@mcgi.org.au',
    services: ['Sunday 9:30 AM', 'Thursday 6:30 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'David Wilson',
    memberCount: 150
  },
  {
    id: 3,
    name: 'MCGI Brisbane',
    region: 'Queensland',
    address: '8 Boyland Avenue, Coopers Plains QLD',
    phone: '+61 412 483 312',
    email: 'mcgibrisbane@mcgi.org.au',
    services: ['Sunday 10:30 AM', 'Tuesday 7:00 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Sarah Johnson',
    memberCount: 95
  },
  {
    id: 4,
    name: 'MCGI Perth',
    region: 'Western Australia',
    address: '30 Bowen Street, Kardinya WA 6163',
    phone: '+61 452 405 409',
    email: 'mcgiperth@mcgi.org.au',
    services: ['Sunday 10:00 AM', 'Friday 6:00 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Michael Brown',
    memberCount: 85
  },
  {
    id: 5,
    name: 'MCGI Adelaide',
    region: 'South Australia',
    address: '272-274 North East Road, Klemzig SA 5087',
    phone: '+61 475 699 136',
    email: 'mcgiadelaide@mcgi.org.au',
    services: ['Sunday 11:00 AM', 'Wednesday 7:30 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Rebecca Taylor',
    memberCount: 70
  },
  {
    id: 6,
    name: 'MCGI Barmera',
    region: 'South Australia',
    address: '19 Kelly Ave, Barmera SA',
    phone: '+61 475 699 136',
    email: 'mcgibarmera@mcgi.org.au',
    services: ['Sunday 9:00 AM', 'Tuesday 6:30 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'James Anderson',
    memberCount: 45
  },
  {
    id: 7,
    name: 'MCGI Cairns',
    region: 'Queensland',
    address: '7 Forest Gardens Blvd, Mount Sheridan QLD 4868',
    phone: '+61 412 483 312',
    email: 'mcgicairns@mcgi.org.au',
    services: ['Sunday 10:00 AM', 'Thursday 7:00 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Emma Roberts',
    memberCount: 60
  },
  {
    id: 8,
    name: 'MCGI Darwin',
    region: 'Northern Territory',
    address: '4/5 Damaso Place, Woolner NT 0820',
    phone: '+61 405 585 252',
    email: 'mcgidarwin@mcgi.org.au',
    services: ['Sunday 9:30 AM', 'Wednesday 6:30 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Thomas Clark',
    memberCount: 55
  },
  {
    id: 9,
    name: 'MCGI Mackay',
    region: 'Queensland',
    address: '2/114 Sydney St, Mackay QLD 4740',
    phone: '+61 412 483 312',
    email: 'mcgimackay@mcgi.org.au',
    services: ['Sunday 10:30 AM', 'Tuesday 7:00 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Olivia Walker',
    memberCount: 50
  },
  {
    id: 10,
    name: 'MCGI Gladstone',
    region: 'Queensland',
    address: '113 Auckland St, Gladstone Central QLD 6480',
    phone: '+61 412 483 312',
    email: 'mcgigladstone@mcgi.org.au',
    services: ['Sunday 9:00 AM', 'Thursday 6:00 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Daniel King',
    memberCount: 45
  },
  {
    id: 11,
    name: 'MCGI Sunshine Coast',
    region: 'Queensland',
    address: '65 Currie Street, Nambour QLD 4560',
    phone: '+61 412 483 312',
    email: 'mcgisunshinecoast@mcgi.org.au',
    services: ['Sunday 10:00 AM', 'Wednesday 7:00 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Sophie Harris',
    memberCount: 65
  },
  {
    id: 12,
    name: 'MCGI Townsville',
    region: 'Queensland',
    address: 'Online Services Only - No Physical Location',
    phone: '+61 412 483 312',
    email: 'mcgitownsville@mcgi.org.au',
    services: ['Sunday 10:00 AM (Online)', 'Wednesday 7:00 PM (Online)'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Matthew Jones',
    memberCount: 35
  },
  {
    id: 13,
    name: 'MCGI Toowoomba',
    region: 'Queensland',
    address: 'Unit 4, 453 Ruthven Street, Toowoomba QLD 4350',
    phone: '+61 412 483 312',
    email: 'mcgitoowoomba@mcgi.org.au',
    services: ['Sunday 9:30 AM', 'Tuesday 6:30 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Jessica Martin',
    memberCount: 55
  },
  {
    id: 14,
    name: 'MCGI Kalgoorlie',
    region: 'Western Australia',
    address: '66 Burt Street, Boulder WA 6432',
    phone: '+61 452 405 409',
    email: 'mcgikalgoorlie@mcgi.org.au',
    services: ['Sunday 11:00 AM', 'Thursday 7:00 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Ryan Thompson',
    memberCount: 40
  },
  {
    id: 15,
    name: 'MCGI Singleton',
    region: 'New South Wales',
    address: '154-156 John St, Singleton 2330',
    phone: '+61 436 309 638',
    email: 'mcgisingleton@mcgi.org.au',
    services: ['Sunday 10:00 AM', 'Wednesday 6:30 PM'],
    image: `${process.env.PUBLIC_URL}/images/about-welcome.jpg`,
    leader: 'Lauren Davis',
    memberCount: 50
  }
];

function LocalChapters() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredChapters, setFilteredChapters] = useState(chapters);
  const [selectedRegion, setSelectedRegion] = useState('All');
  
  // Hero background image path using process.env.PUBLIC_URL
  const heroBackgroundImage = `${process.env.PUBLIC_URL}/images/chapters.jpg`;
  
  // Observer for scroll animations
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in');
        }
      });
    }, { threshold: 0.1 });
    
    document.querySelectorAll('.animate').forEach(el => {
      observer.observe(el);
    });
    
    return () => {
      document.querySelectorAll('.animate').forEach(el => {
        observer.unobserve(el);
      });
    };
  }, []);
  
  // Handle filtering when search term or region changes
  const handleFiltering = () => {
    let results = [...chapters]; // Create a fresh copy of chapters array
    
    // Filter by region
    if (selectedRegion !== 'All') {
      results = results.filter(chapter => 
        chapter.region === selectedRegion
      );
    }
    
    // Filter by search term
    if (searchTerm) {
      const lowercasedSearch = searchTerm.toLowerCase();
      results = results.filter(chapter => 
        chapter.name.toLowerCase().includes(lowercasedSearch) ||
        chapter.address.toLowerCase().includes(lowercasedSearch) ||
        chapter.region.toLowerCase().includes(lowercasedSearch)
      );
    }
    
    setFilteredChapters(results);
  };
  
  // Apply filtering when search term or region changes
  useEffect(() => {
    handleFiltering();
  }, [searchTerm, selectedRegion]);
  
  // Get unique regions for filter
  const regions = ['All', ...new Set(chapters.map(chapter => chapter.region))];

  return (
    <div className="chapters-container">
      <div className="chapters-hero" style={{background: `linear-gradient(to right, rgba(49, 130, 206, 0.9), rgba(66, 153, 225, 0.8)), url(${heroBackgroundImage}) center/cover no-repeat`}}>
        <div className="hero-overlay"></div>
        <div className="chapters-hero-content animate">
          <span className="chapters-tag">Our Community</span>
          <h1>Find Your Local Chapter</h1>
          <p>Connect with believers in your area and become part of our growing community spreading peace, hope, and love.</p>
        </div>
      </div>
      
      <div className="chapters-content">
        <div className="chapters-search-section animate">
          <div className="search-container">
            <div className="search-box">
              <i className="fas fa-search"></i>
              <input 
                type="text" 
                placeholder="Search by name, region, or address..." 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            
            <div className="region-filter">
              <div className="filter-label">Filter by Region:</div>
              <div className="region-buttons">
                {regions.map(region => (
                  <button 
                    key={region} 
                    className={`region-btn ${selectedRegion === region ? 'active' : ''}`}
                    onClick={() => setSelectedRegion(region)}
                  >
                    {region}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        
        <div className="chapters-grid">
          {filteredChapters.length > 0 ? (
            filteredChapters.map(chapter => (
              <div className="chapter-card animate" key={chapter.id}>
                <div className="chapter-image">
                  <div className="region-icon">
                    {chapter.region === 'Victoria' && <i className="fas fa-landmark"></i>}
                    {chapter.region === 'New South Wales' && <i className="fas fa-city"></i>}
                    {chapter.region === 'Queensland' && <i className="fas fa-umbrella-beach"></i>}
                    {chapter.region === 'Western Australia' && <i className="fas fa-mountain"></i>}
                    {chapter.region === 'South Australia' && <i className="fas fa-leaf"></i>}
                    {chapter.region === 'Northern Territory' && <i className="fas fa-sun"></i>}
                  </div>
                  <div className="chapter-badge">
                    <span>{chapter.region}</span>
                  </div>
                </div>
                <div className="chapter-details">
                  <h3>{chapter.name}</h3>
                  <div className="chapter-info">
                    <p><i className="fas fa-map-marker-alt"></i> {chapter.address}</p>
                    <p><i className="fas fa-phone"></i> {chapter.phone}</p>
                    <p><i className="fas fa-envelope"></i> {chapter.email}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-chapters animate">
              <i className="fas fa-search"></i>
              <p>No chapters found matching your search criteria.</p>
              <button className="btn-primary" onClick={() => {setSearchTerm(''); setSelectedRegion('All');}}>Reset Search</button>
            </div>
          )}
        </div>
        
        <div className="chapters-cta animate">
          <div className="cta-content">
            <h2>Can't Find a Chapter Near You?</h2>
            <p>If there's no MCGI chapter in your area, we'd love to help you connect with our community or even start a new chapter.</p>
            <div className="cta-buttons">
              <Link to="/contact" className="btn-primary">Contact Us <i className="fas fa-arrow-right"></i></Link>
              <button className="btn-outline"><i className="fas fa-video"></i> Join Online Services</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocalChapters; 