import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './EventsPage.css';

function EventsPage() {
  // Background image path
  const heroBackgroundImage = `${process.env.PUBLIC_URL}/images/events.jpg`;
  
  // Add scroll reveal animation effect
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in');
        }
      });
    }, observerOptions);

    const animatedElements = document.querySelectorAll('.animate');
    animatedElements.forEach(el => observer.observe(el));

    return () => {
      animatedElements.forEach(el => observer.unobserve(el));
    };
  }, []);

  // Define regular events with details
  const regularEvents = [
    {
      id: 'bible-studies',
      title: 'Bible Studies',
      description: 'Join our in-depth study of the scriptures as we examine biblical teachings, principles, and applications to modern life.',
      schedule: 'Everyday at 7:00 PM',
      image: process.env.PUBLIC_URL + '/images/about/about-1.jpg',
      icon: 'fas fa-book-open',
      category: 'worship'
    },
    {
      id: 'brethren-day',
      title: 'Brethren Day',
      description: 'A special day dedicated to fellowship among members, featuring shared meals, activities, and strengthening of community bonds.',
      schedule: 'Every quarter',
      image: process.env.PUBLIC_URL + '/images/about/about-2.jpg',
      icon: 'fas fa-users',
      category: 'fellowship'
    },
    {
      id: 'mcgi-cares',
      title: 'MCGI Cares',
      description: 'Our community outreach program serving those in need through food drives, medical missions, disaster relief, and other charitable activities.',
      schedule: 'Contact us for schedule',
      image: process.env.PUBLIC_URL + '/images/charity.png',
      icon: 'fas fa-hands-helping',
      category: 'outreach'
    },
    {
      id: 'prayer-meeting',
      title: 'Prayer Meeting',
      description: 'Gather together for a time of communal prayer, worship, and meditation on God\'s word to strengthen our spiritual connection.',
      schedule: 'Every Wednesday, 7:00 PM',
      image: process.env.PUBLIC_URL + '/images/about/about-3.jpg',
      icon: 'fas fa-pray',
      category: 'worship'
    },
    {
      id: 'worship-service',
      title: 'Worship Service',
      description: 'Our main worship gathering featuring praise and worship, biblical teachings, and communion as we honor God together.',
      schedule: 'Every Saturday, 2:00 PM',
      image: process.env.PUBLIC_URL + '/images/about/about-4.jpg',
      icon: 'fas fa-hands',
      category: 'worship'
    },
    {
      id: 'thanksgiving',
      title: 'Thanksgiving',
      description: 'A special service dedicated to expressing gratitude to God for His blessings, featuring testimonies and celebrations.',
      schedule: 'Every Saturday, 7:00 PM',
      image: process.env.PUBLIC_URL + '/images/about/about-5.jpg',
      icon: 'fas fa-dove',
      category: 'worship'
    }
  ];

  return (
    <div className="events-container">
      <div className="events-hero" style={{background: `linear-gradient(to right, rgba(49, 130, 206, 0.8), rgba(66, 153, 225, 0.8)), url(${heroBackgroundImage}) center/cover no-repeat`}}>
        <div className="events-hero-content animate">
          <span className="events-tag">Events</span>
          <h1>Join Us in Fellowship</h1>
          <p>Connect with our community through worship services, outreach programs, and special gatherings.</p>
          <div className="hero-buttons">
            <Link to="/contact" className="btn-hero-primary">
              Join Our Community <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="hero-overlay"></div>
      </div>
      
      <div className="events-content">
        {/* Regular Events Section */}
        <section className="regular-events">
          <div className="section-header animate">
            <h2>Regular Events</h2>
            <div className="title-underline"></div>
            <p>Join us for our recurring events and become part of our growing community</p>
          </div>
          
          <div className="regular-events-grid">
            {regularEvents.map(event => (
              <div className="regular-event-card animate" key={event.id}>
                <div className="regular-event-icon">
                  <i className={event.icon}></i>
                </div>
                <div className="regular-event-content">
                  <h3>{event.title}</h3>
                  <p className="event-schedule">
                    <i className="fas fa-calendar-alt"></i> {event.schedule}
                  </p>
                  <p className="regular-event-description">{event.description}</p>
                  <div className="learn-more-container">
                    <a href={`#${event.id}`} className="learn-more">
                      Learn More <i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default EventsPage; 