import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './AboutPage.css';

function AboutPage() {
  const [iconsLoaded, setIconsLoaded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [renderComplete, setRenderComplete] = useState(false);
  const pageRef = useRef(null);

  // Handle initial render
  useEffect(() => {
    // Set a flag when component has mounted
    setRenderComplete(true);
  }, []);

  // Force icon loading
  useEffect(() => {
    if (renderComplete) {
      const loadIcons = () => {
        const fontAwesome = document.querySelector('link[href*="fontawesome"]');
        if (fontAwesome) {
          // Force a repaint on Font Awesome icons
          setTimeout(() => {
            if (pageRef.current) {
              const icons = pageRef.current.querySelectorAll('.fas, .fab, .far, .fa');
              if (icons.length > 0) {
                icons.forEach(icon => {
                  const display = window.getComputedStyle(icon).display;
                  icon.style.display = 'none';
                  // Trigger reflow
                  void icon.offsetHeight;
                  icon.style.display = display;
                });
                setIconsLoaded(true);
                console.log('About page icons refreshed:', icons.length);
              } else {
                setTimeout(loadIcons, 50);
              }
            }
          }, 200);
        } else {
          setTimeout(loadIcons, 100);
        }
      };
      
      loadIcons();
    }
  }, [renderComplete]);

  // Add scroll reveal animation effect
  useEffect(() => {
    if (renderComplete) {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-in');
          }
        });
      }, observerOptions);

      const animatedElements = document.querySelectorAll('.animate');
      animatedElements.forEach(el => observer.observe(el));

      return () => {
        animatedElements.forEach(el => observer.unobserve(el));
      };
    }
  }, [renderComplete]);

  // Preload images
  useEffect(() => {
    if (renderComplete) {
      const imagesToPreload = [
        '/images/about-welcome.jpg',
        '/images/chapters.jpg',
        '/images/bro-eli-soriano.jpg',
        '/images/kuya-edited.jpg',
        '/images/bg/about-bg.jpg',
        '/images/charity.png'
      ];
      
      let loadedCount = 0;
      const totalImages = imagesToPreload.length;
      
      imagesToPreload.forEach(src => {
        const img = new Image();
        img.src = `${process.env.PUBLIC_URL}${src}`;
        img.onload = () => {
          loadedCount++;
          if (loadedCount === totalImages) {
            setImagesLoaded(true);
            console.log('All About page images loaded');
          }
        };
        img.onerror = () => {
          loadedCount++;
          console.error(`Failed to load: ${src}`);
          if (loadedCount === totalImages) {
            setImagesLoaded(true);
          }
        };
      });
    }
  }, [renderComplete]);

  return (
    <div className="about-container" ref={pageRef} style={{ opacity: imagesLoaded ? 1 : 0.95 }}>
      <div className="about-hero" style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${process.env.PUBLIC_URL}/images/about-welcome.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        <div className="about-hero-content">
          <span className="about-tag">About Us</span>
          <h1>Members Church of God International</h1>
          <p>Australia Chapter - Guided by faith, united in purpose, and devoted to spreading God's word.</p>
        </div>
        <div className="hero-overlay"></div>
      </div>
      
      <div className="about-content">
        <div className="about-section animate">
          <div className="about-image-container">
            <img 
              src={`${process.env.PUBLIC_URL}/images/chapters.jpg`} 
              alt="MCGI Australia Community" 
              className="about-image" 
              loading="eager"
            />
            <div className="image-accent"></div>
          </div>
          <div className="about-text">
            <span className="section-tag">Our Story</span>
            <h2>Welcome to MCGI Australia</h2>
            <div className="title-underline"></div>
            <p>
              The Members Church of God International (MCGI) is a Christian religious organization with its main 
              headquarters in the Philippines and various remote points and coordinating centers around the globe, 
              including our growing Australian chapter.
            </p>
            <p>
              While the majority of Church members are Filipino nationals, through our continuous effort to 
              propagate the Gospel of the Lord Jesus Christ across all nations, the Church has grown into a 
              diverse group of different racial descents, with our Australian congregation reflecting this 
              global diversity.
            </p>
            <p>
              MCGI is recognized worldwide through its flagship program, Ang Dating Daan (The Old Path), 
              which is the longest-running religious program in the Philippines. The program has foreign 
              counterparts in more than 70 countries, including Australia, where we continue to share 
              biblical teachings and foster a strong community of faith.
            </p>
          </div>
        </div>
        
        <div className="about-section animate">
          <div className="about-text">
            <span className="section-tag">Our Leaders</span>
            <h2>Overall Servants</h2>
            <div className="title-underline"></div>
            <p>
              The Members Church of God International is led by dedicated servants who guide our global congregation
              with wisdom, compassion, and a deep commitment to biblical truth.
            </p>
          </div>
        </div>
        
        <div className="servant-profiles animate">
          <div className="servant-profile">
            <div className="servant-image-container">
              <img 
                src={`${process.env.PUBLIC_URL}/images/bro-eli-soriano.jpg`} 
                alt="Brother Eli Soriano" 
                className="servant-image" 
                loading="eager"
              />
            </div>
            <div className="servant-details">
              <h3>Bro. Eli Soriano (1947-2021)</h3>
              <p>
                Bro. Eli Soriano served as the Overall Servant of the Members Church of God International 
                until his passing in 2021. He was globally recognized as the host of the popular religious 
                program "Ang Dating Daan" (The Old Path). His profound knowledge of the Bible and unwavering 
                commitment to sharing God's word transformed countless lives around the world, including here 
                in Australia.
              </p>
              <p>
                Known for his ability to answer religious questions with biblical precision, Bro. Eli dedicated 
                his life to preaching the Gospel and helping people understand the Scriptures in their purest form.
              </p>
            </div>
          </div>
          
          <div className="servant-profile">
            <div className="servant-image-container">
              <img 
                src={`${process.env.PUBLIC_URL}/images/kuya-edited.jpg`} 
                alt="Brother Daniel Razon" 
                className="servant-image" 
                loading="eager"
              />
            </div>
            <div className="servant-details">
              <h3>Bro. Daniel Razon</h3>
              <p>
                Beyond being a broadcast journalist and a prominent television and radio personality, 
                Bro. Daniel S. Razon serves as the current Overall Servant of the Members Church of God 
                International. Under his leadership, MCGI continues to grow globally, with the Australian 
                chapter benefiting from his vision of spreading biblical truths and engaging in charitable 
                works throughout our communities.
              </p>
              <p>
                Affectionately known as "Kuya Daniel" (Brother Daniel), he has expanded MCGI's charitable 
                initiatives worldwide while remaining committed to teaching the Gospel with clarity and compassion.
              </p>
            </div>
          </div>
          
          <blockquote className="servant-quote">
            "For we are His workmanship, created in Christ Jesus for good works, which God prepared 
            beforehand that we should walk in them." <span className="verse">Ephesians 2:10</span>
          </blockquote>
        </div>

        <div className="about-section reverse animate">
          <div className="about-text">
            <span className="section-tag">Our Belief</span>
            <h2>What We Believe</h2>
            <div className="title-underline"></div>
            <p>
              Guided by the Holy Scriptures, MCGI believes in the Almighty God, His begotten Son the Lord 
              Jesus Christ, and the Holy Spirit. Our teachings and practices are firmly rooted in biblical 
              truths, with an emphasis on applying these principles in our daily lives.
            </p>
            <p>
              Here in Australia, we strive to preserve these core beliefs while fostering a welcoming 
              environment for all who seek to learn about God's word. We encourage open discussions and 
              Bible-based learning, allowing members to grow spiritually through understanding and 
              application of Scripture.
            </p>
            <blockquote>
              "All Scripture is given by inspiration of God, and is profitable for doctrine, for reproof, 
              for correction, for instruction in righteousness." <span className="verse">2 Timothy 3:16</span>
            </blockquote>
          </div>
          <div className="about-image-container">
            <img 
              src={`${process.env.PUBLIC_URL}/images/bg/about-bg.jpg`} 
              alt="Bible Study" 
              className="about-image" 
              loading="eager"
            />
            <div className="image-accent"></div>
          </div>
        </div>
        
        <div className="about-section animate">
          <div className="about-image-container">
            <img 
              src={`${process.env.PUBLIC_URL}/images/charity.png`} 
              alt="MCGI Charity" 
              className="about-image" 
              loading="eager"
            />
            <div className="image-accent"></div>
          </div>
          <div className="about-text">
            <span className="section-tag">Our Work</span>
            <h2>Charity and Community Service</h2>
            <div className="title-underline"></div>
            <p>
              Central to MCGI's mission are charitable projects that provide free social services to those 
              in need. The Australian chapter actively participates in these initiatives, focusing on local 
              community needs while supporting global efforts.
            </p>
            <p>
              Our charitable works in Australia include food programs, medical missions, educational 
              support, disaster relief, and community development projects. Through these efforts, we 
              express our devotion and fulfill our mission to spread love and compassion, creating a 
              better world for all Australians.
            </p>
            <div className="charity-initiatives">
              <div className="charity-initiative">
                <div className="initiative-icon">
                  <i className="fas fa-utensils"></i>
                </div>
                <div className="initiative-text">
                  <h4>Food Programs</h4>
                  <p>Providing meals to those in need across Australian communities</p>
                </div>
              </div>
              <div className="charity-initiative">
                <div className="initiative-icon">
                  <i className="fas fa-graduation-cap"></i>
                </div>
                <div className="initiative-text">
                  <h4>Educational Support</h4>
                  <p>Offering scholarships and learning resources to Australian students</p>
                </div>
              </div>
            </div>
            <blockquote>
              "Give, and it will be given to you: good measure, pressed down, shaken together, and 
              running over will be put into your bosom." <span className="verse">Luke 6:38</span>
            </blockquote>
          </div>
        </div>
        
        <div className="about-section reverse animate">
          <div className="about-text">
            <span className="section-tag">Our Services</span>
            <h2>Religious Services in Australia</h2>
            <div className="title-underline"></div>
            <p>
              MCGI holds regular and special Church services and events to strengthen members' spirituality 
              and faith, while also creating opportunities for brethren to meet face-to-face and care for 
              each other's well-being (Hebrews 10:24).
            </p>
            <p>
              In Australia, we conduct Thanksgiving services, Prayer Meetings, and Worship Services across 
              various locations. We also participate in global gatherings through live video-streaming, 
              connecting our Australian congregation with the worldwide MCGI community.
            </p>
            <p>
              These services are open to all who wish to attend, regardless of background, as we welcome 
              everyone seeking to learn more about God's word in a supportive and nurturing environment.
            </p>
          </div>
          <div className="about-image-container">
            <img 
              src={`${process.env.PUBLIC_URL}/images/about-1.jpg`} 
              alt="Church Service" 
              className="about-image" 
              loading="eager"
            />
            <div className="image-accent"></div>
          </div>
        </div>
        
        <div className="about-cta animate">
          <div className="cta-container">
            <h2>Join Our Community</h2>
            <p>
              Experience the warmth and spiritual growth that comes from being part of our global church family. 
              With a strong presence in Australia, we invite you to fellowship with us and discover the life-changing 
              message of God's word.
            </p>
            <div className="cta-buttons">
              <Link to="/contact" className="btn btn-primary">
                Contact Us <i className="fas fa-envelope"></i>
              </Link>
              <Link to="/chapters" className="btn btn-secondary">
                Find a Chapter <i className="fas fa-map-marker-alt"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage; 