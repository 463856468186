import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [iconsLoaded, setIconsLoaded] = useState(false);
  const location = useLocation();
  const socialIconsRef = useRef(null);
  
  // Use logo from public directory
  const logoPath = `${process.env.PUBLIC_URL}/images/logo.png`;

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  
  // Handle scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // Close mobile menu when location changes
  useEffect(() => {
    setClick(false);
  }, [location]);

  // Handle font awesome icon loading
  useEffect(() => {
    // Force the browser to load Font Awesome before showing icons
    const loadFontAwesome = () => {
      const fontAwesome = document.querySelector('link[href*="fontawesome"]');
      if (fontAwesome) {
        // Delay to ensure font awesome is loaded
        setTimeout(() => {
          setIconsLoaded(true);
          
          // Force a repaint of icons after they're shown
          if (socialIconsRef.current) {
            const icons = socialIconsRef.current.querySelectorAll('.fab');
            icons.forEach(icon => {
              const display = window.getComputedStyle(icon).display;
              icon.style.display = 'none';
              // Trigger reflow
              void icon.offsetHeight;
              icon.style.display = display;
            });
          }
        }, 300);
      } else {
        setTimeout(loadFontAwesome, 100);
      }
    };
    
    loadFontAwesome();
  }, []);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <>
      <div className="social-header">
        <div className="social-header-container">
          <div className="social-icons" ref={socialIconsRef} style={{ visibility: iconsLoaded ? 'visible' : 'hidden' }}>
            <a href="https://www.facebook.com/MCGI.org/" target="_blank" rel="noopener noreferrer" className="social-icon-header" aria-label="Facebook">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://x.com/mcgidotorg" target="_blank" rel="noopener noreferrer" className="social-icon-header" aria-label="X (Twitter)">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com/mcgidotorg/" target="_blank" rel="noopener noreferrer" className="social-icon-header" aria-label="Instagram">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.youtube.com/mcgichannel" target="_blank" rel="noopener noreferrer" className="social-icon-header" aria-label="YouTube">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
      <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={logoPath} alt="MCGI Logo" className="navbar-logo-img" />
          </Link>
          
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className={`nav-item ${isActive('/')}`}>
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                <span className="nav-link-text">Home</span>
              </Link>
            </li>
            <li className={`nav-item ${isActive('/about')}`}>
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                <span className="nav-link-text">About</span>
              </Link>
            </li>
            <li className={`nav-item ${isActive('/events')}`}>
              <Link to="/events" className="nav-links" onClick={closeMobileMenu}>
                <span className="nav-link-text">Events</span>
              </Link>
            </li>
            <li className={`nav-item ${isActive('/chapters')}`}>
              <Link to="/chapters" className="nav-links" onClick={closeMobileMenu}>
                <span className="nav-link-text">Chapters</span>
              </Link>
            </li>
            <li className={`nav-item ${isActive('/contact')}`}>
              <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
                <span className="nav-link-text">Contact</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar; 