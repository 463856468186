import React, { useEffect, useState } from 'react';
import HeroSection from '../components/HeroSection';
import { Link } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  // State to track if the component is mounted for loading effects
  const [isLoaded, setIsLoaded] = useState(false);

  // Add scroll reveal animation effect
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-in');
        }
      });
    }, observerOptions);

    const animatedElements = document.querySelectorAll('.animate');
    animatedElements.forEach(el => observer.observe(el));
    
    // Set isLoaded to true after component mounts
    setIsLoaded(true);

    return () => {
      animatedElements.forEach(el => observer.unobserve(el));
    };
  }, []);

  return (
    <>
      <HeroSection />
      <div className={`home-container ${isLoaded ? 'loaded' : ''}`}>
        <div className="home-section">
          <div className="section-header animate">
            <span className="section-tag">Welcome</span>
            <h2 className="section-title">Members Church of God International</h2>
            <div className="title-underline"></div>
            <p className="section-subtitle">
              Spreading the message of peace, hope, and love through faith and community service.
              Join us in our mission to share God's word and make a positive impact in the world.
            </p>
          </div>
          
          <div className="values-container">
            <div className="value-card animate">
              <div className="value-icon">
                <i className="fas fa-dove"></i>
              </div>
              <h3>Peace</h3>
              <p>Experience the peace that comes from true understanding of God's word and fellowship with like-minded believers.</p>
              <div className="card-overlay"></div>
            </div>
            
            <div className="value-card animate">
              <div className="value-icon">
                <i className="fas fa-heart"></i>
              </div>
              <h3>Love</h3>
              <p>We demonstrate God's love through our actions, serving others and building a supportive community of faith.</p>
              <div className="card-overlay"></div>
            </div>
            
            <div className="value-card animate">
              <div className="value-icon">
                <i className="fas fa-seedling"></i>
              </div>
              <h3>Hope</h3>
              <p>Find hope for the future through spiritual guidance and the unwavering promise of God's word.</p>
              <div className="card-overlay"></div>
            </div>
          </div>
          
          <div className="section-header animate">
            <span className="section-tag">Explore</span>
            <h2>Discover Our Community</h2>
            <div className="title-underline"></div>
          </div>
          
          <div className="home-grid">
            <div className="home-card animate">
              <div className="card-image-wrapper">
                <img 
                  src={`${process.env.PUBLIC_URL}/images/about-welcome.jpg`}
                  alt="About MCGI" 
                  className="home-img" 
                  loading="lazy"
                  srcSet={`${process.env.PUBLIC_URL}/images/about-welcome.jpg 800w`}
                  sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                />
                <div className="card-badge">Our Story</div>
              </div>
              <div className="home-card-content">
                <h3>Who We Are</h3>
                <p>Learn more about the Members Church of God International, our mission, and our journey of faith.</p>
                <Link to="/about" className="btn btn-primary">
                  Learn More <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            
            <div className="home-card animate">
              <div className="card-image-wrapper">
                <img 
                  src={`${process.env.PUBLIC_URL}/images/events.jpg`} 
                  alt="Events" 
                  className="home-img" 
                  loading="lazy"
                  srcSet={`${process.env.PUBLIC_URL}/images/events.jpg 800w`}
                  sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                />
                <div className="card-badge">Join Us</div>
              </div>
              <div className="home-card-content">
                <h3>Our Gatherings</h3>
                <p>Join our regular worship services, Bible studies, and special events that strengthen our faith and community.</p>
                <Link to="/events" className="btn btn-primary">
                  View Events <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            
            <div className="home-card animate">
              <div className="card-image-wrapper">
                <img 
                  src={`${process.env.PUBLIC_URL}/images/charity.png`} 
                  alt="Charity Work" 
                  className="home-img" 
                  loading="lazy"
                  srcSet={`${process.env.PUBLIC_URL}/images/charity.png 800w`}
                  sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 33vw"
                />
                <div className="card-badge">Outreach</div>
              </div>
              <div className="home-card-content">
                <h3>Community Service</h3>
                <p>Discover how we reach out to communities through charitable activities and service initiatives.</p>
                <Link to="/about" className="btn btn-primary">
                  Our Work <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          
          <div className="cta-section animate">
            <div className="cta-content">
              <span className="cta-tag">Join Us</span>
              <h2>Become Part of Our Community</h2>
              <p>Experience the joy of fellowship and spiritual growth with MCGI. Find a local chapter near you and connect with believers who share your faith journey.</p>
              <Link to="/chapters" className="btn btn-secondary">
                Find a Chapter <i className="fas fa-map-marker-alt"></i>
              </Link>
            </div>
            <div className="cta-shape"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage; 